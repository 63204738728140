define("raquelmullins/routes/collection/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    async model(params) {
      return await this.store.findRecord('collections', params.collection_id);
    },

    async afterModel(model) {
      this._super(...arguments);

      model.photos.sort((a, b) => a.order - b.order);

      for (let record of model.photos) {
        let image = await this.loadImage(record.src);
        record.h = image.height;
        record.w = image.width;
      }
    },

    async loadImage(src) {
      return new Ember.RSVP.Promise(resolve => {
        let image = new Image();
        image.onload = resolve(image);
        image.src = src;
      });
    }

  });

  _exports.default = _default;
});