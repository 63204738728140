define("raquelmullins/templates/collection/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ZJytkPRl",
    "block": "{\"symbols\":[\"photoswipe\",\"photos\",\"index\"],\"statements\":[[7,\"h1\"],[9],[1,[23,[\"model\",\"title\"]],false],[10],[0,\"\\n\"],[7,\"p\"],[9],[1,[23,[\"model\",\"description\"]],false],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"row\"],[9],[0,\"\\n\\t\"],[1,[23,[\"model\",\"video\"]],true],[0,\"\\n\"],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"row collection\"],[9],[0,\"\\n\\t\"],[7,\"div\"],[11,\"class\",\"col-12\"],[9],[0,\"\\n\"],[4,\"photo-swipe\",null,[[\"items\",\"history\"],[[23,[\"model\",\"photos\"]],false]],{\"statements\":[[4,\"each\",[[23,[\"model\",\"photos\"]]],null,{\"statements\":[[0,\"\\t\\t\\t\\t\"],[7,\"div\"],[11,\"class\",\"row mt-3\"],[9],[0,\"\\n\\t\\t\\t\\t\\t\"],[7,\"div\"],[11,\"class\",\"col-12 col-md-5\"],[9],[0,\"\\n\\t\\t\\t\\t\\t\\t\"],[7,\"img\"],[11,\"class\",\"card-img-top img-fluid\"],[12,\"src\",[22,2,[\"src\"]]],[12,\"alt\",[22,2,[\"heading\"]]],[9],[3,\"action\",[[22,0,[]],[22,1,[\"actions\",\"open\"]],[27,\"hash\",null,[[\"index\"],[[22,3,[]]]]]]],[10],[0,\"\\n\\t\\t\\t\\t\\t\"],[10],[0,\"\\n\\t\\t\\t\\t\\t\"],[7,\"div\"],[11,\"class\",\"col-12 col-md-5\"],[9],[0,\"\\n\\t\\t\\t\\t\\t\\t\"],[7,\"h4\"],[11,\"class\",\"\"],[9],[1,[22,2,[\"heading\"]],false],[10],[0,\"\\n\\t\\t\\t\\t\\t\\t\"],[7,\"h4\"],[11,\"class\",\"\"],[9],[1,[22,2,[\"subheading\"]],false],[10],[0,\"\\n\\t\\t\\t\\t\\t\"],[10],[0,\"\\n\\t\\t\\t\\t\"],[10],[0,\"\\n\\t\\t\\t\\t\\t\"],[7,\"hr\"],[9],[10],[0,\"\\n\"]],\"parameters\":[2,3]},null]],\"parameters\":[1]},null],[0,\"\\t\"],[10],[0,\"\\n\"],[10],[0,\"\\n\"],[1,[21,\"scroll-top\"],false],[0,\"\\n\"],[1,[21,\"outlet\"],false],[0,\"\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "raquelmullins/templates/collection/index.hbs"
    }
  });

  _exports.default = _default;
});