define("raquelmullins/router", ["exports", "raquelmullins/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });
  Router.map(function () {
    this.route('collections', function () {
      this.route('add');
      this.route('index', {
        path: '/'
      });
    });
    this.route('collection', function () {
      this.route('index', {
        path: '/:collection_id'
      });
      this.route('edit', {
        path: ':collection_id/edit'
      });
    });
    this.route('bio');
    this.route('contact');
    this.route('ideas', function () {
      this.route('index', {
        path: '/'
      });
      this.route('add');
    });
    this.route('idea', function () {
      this.route('index', {
        path: '/:idea_id'
      });
      this.route('edit', {
        path: ':idea_id/edit'
      });
    });
  });
  var _default = Router;
  _exports.default = _default;
});