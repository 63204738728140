define("raquelmullins/templates/components/brand-header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "EhFQ/7PG",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\"],[11,\"class\",\"row\"],[9],[0,\"\\n\\t\"],[7,\"div\"],[11,\"class\",\"col-12 col-sm-10 col-lg-4 logo\"],[9],[0,\"\\n\\t\\t\"],[7,\"div\"],[11,\"class\",\"row\"],[9],[0,\"\\n\\t\\t\\t\"],[7,\"button\"],[12,\"class\",[28,[\"col-2 d-block d-md-none hamburger hamburger--squeeze \",[27,\"if\",[[23,[\"openHamburger\"]],\"is-active\"],null]]]],[11,\"data-toggle\",\"collapse\"],[11,\"data-target\",\"#menu\"],[11,\"type\",\"button\"],[9],[0,\"\\n\\t\\t\\t\\t\\t\"],[7,\"span\"],[11,\"class\",\"hamburger-box\"],[9],[0,\"\\n\\t\\t\\t\\t\\t\"],[7,\"span\"],[11,\"class\",\"hamburger-inner\"],[9],[10],[0,\"\\n\\t\\t\\t\\t\\t\"],[10],[0,\"\\n\\t\\t\\t\"],[3,\"action\",[[22,0,[]],\"toggleHamburger\"]],[10],[0,\"\\n\"],[4,\"link-to\",[\"index\"],[[\"class\"],[\"col-10 col-md-12\"]],{\"statements\":[[0,\"\\t\\t\\t\\t\"],[7,\"img\"],[11,\"src\",\"https://firebasestorage.googleapis.com/v0/b/raquelmullins.appspot.com/o/raquelmullinslogo.png?alt=media&token=7b6b86e9-4115-49f6-9929-0671bd35ad80\"],[11,\"alt\",\"raquelmullins\"],[9],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\t\\t\"],[10],[0,\"\\n\\t\"],[10],[0,\"\\n\"],[10],[0,\"\\n\\t\"],[7,\"nav\"],[12,\"class\",[28,[\"d-none d-md-block col-12 col-md-2 col-lg-1 \",[27,\"if\",[[23,[\"openHamburger\"]],\"d-block\"],null]]]],[11,\"id\",\"menu\"],[9],[0,\"\\n\\t\\t\"],[1,[27,\"nav-bar\",null,[[\"toggleHamburger\"],[[27,\"action\",[[22,0,[]],\"toggleHamburger\"],null]]]],false],[0,\"\\t\\t\\t\\n\\t\"],[10],[0,\"\\n\"],[14,1]],\"hasEval\":false}",
    "meta": {
      "moduleName": "raquelmullins/templates/components/brand-header.hbs"
    }
  });

  _exports.default = _default;
});