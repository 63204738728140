define("raquelmullins/routes/ideas/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    async model(params) {
      let ideas = await this.store.findAll('ideas').then(function (cols) {
        let newSort = cols.content.map(a => a._record);
        newSort.sort((a, b) => b.order - a.order);

        if (params.public == 'true') {
          let filterSort = newSort.filter(x => x.public == 'true');
          return filterSort;
        } else if (params.public == 'false') {
          let filterSort = newSort.filter(x => x.public == 'false');
          return filterSort;
        } else {
          return newSort;
        }
      });
      return ideas;
    }

  });

  _exports.default = _default;
});