define("raquelmullins/templates/ideas/add", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+BXiCivr",
    "block": "{\"symbols\":[],\"statements\":[[7,\"h1\"],[9],[0,\"Add Idea\"],[10],[0,\"\\n\\n\"],[7,\"label\"],[11,\"for\",\"corder\"],[9],[0,\"Idea Order (higher is later)\"],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"input-group mb-3\"],[9],[0,\"\\n\\t\"],[1,[27,\"input\",null,[[\"placeholder\",\"value\",\"class\",\"id\",\"required\",\"type\"],[\"order\",[23,[\"order\"]],\"form-control col-12 col-sm-8\",\"order\",true,\"number\"]]],false],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[7,\"label\"],[11,\"for\",\"title\"],[9],[0,\"Title\"],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"input-group mb-3\"],[9],[0,\"\\n\\t\"],[1,[27,\"input\",null,[[\"placeholder\",\"value\",\"class\",\"id\",\"required\"],[\"title\",[23,[\"title\"]],\"form-control col-12 col-sm-8\",\"title\",true]]],false],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[7,\"label\"],[11,\"for\",\"title\"],[9],[0,\"Description\"],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"input-group mb-3\"],[9],[0,\"\\n    \"],[1,[27,\"textarea\",null,[[\"rows\",\"placeholder\",\"value\",\"class\",\"id\",\"required\"],[3,\"description\",[23,[\"description\"]],\"form-control col-12 col-sm-8\",\"description\",true]]],false],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[7,\"label\"],[11,\"for\",\"image\"],[9],[0,\"Title Image Url\"],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"input-group mb-3\"],[9],[0,\"\\n\\t\"],[1,[27,\"input\",null,[[\"placeholder\",\"value\",\"class\",\"id\",\"required\"],[\"title image url\",[23,[\"image\"]],\"form-control col-12 col-sm-8\",\"image\",true]]],false],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[7,\"button\"],[11,\"class\",\"btn btn-primary\"],[9],[0,\"Add Idea\"],[3,\"action\",[[22,0,[]],\"addRecord\"]],[10],[0,\"\\n\\n\"],[1,[21,\"outlet\"],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "raquelmullins/templates/ideas/add.hbs"
    }
  });

  _exports.default = _default;
});