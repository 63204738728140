define("raquelmullins/templates/ideas/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "PZ4gfcGj",
    "block": "{\"symbols\":[\"idea\"],\"statements\":[[7,\"div\"],[11,\"class\",\"row justify-content-around no-gutters text-center\"],[9],[0,\"\\n\"],[4,\"each\",[[22,0,[\"model\"]]],null,{\"statements\":[[0,\"\\t\\t\"],[7,\"div\"],[11,\"class\",\"card ideas col-12 col-xl-5 mb-3\"],[9],[0,\"\\n\"],[4,\"link-to\",[\"idea\",[22,1,[\"id\"]]],null,{\"statements\":[[0,\"\\t\\t\\t\\t\"],[7,\"div\"],[11,\"class\",\"card-header\"],[9],[0,\"\\n\\t\\t\\t\\t\\t\"],[7,\"h4\"],[9],[1,[22,1,[\"title\"]],false],[10],[0,\"\\n\\t\\t\\t\\t\"],[10],[0,\"\\n\\t\\t\\t\\t\"],[7,\"div\"],[11,\"class\",\"img-holder\"],[9],[0,\"\\n\\t\\t\\t\\t\\t\"],[7,\"img\"],[11,\"class\",\"\"],[12,\"src\",[22,1,[\"image\"]]],[12,\"alt\",[22,1,[\"title\"]]],[9],[10],[0,\"\\n\\t\\t\\t\\t\"],[10],[0,\"\\n\\t\\t\\t\\t\"],[7,\"div\"],[11,\"class\",\"idea-desc\"],[9],[0,\"\\n\\t\\t\\t\\t\\t\"],[7,\"p\"],[9],[1,[22,1,[\"description\"]],false],[10],[0,\"\\n\\t\\t\\t\\t\"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\t\\t\"],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[10],[0,\"\\n\\n\"],[1,[21,\"outlet\"],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "raquelmullins/templates/ideas/index.hbs"
    }
  });

  _exports.default = _default;
});