define("raquelmullins/routes/collection/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    async model(params) {
      let gallery = await this.store.findRecord('collections', params.collection_id);
      gallery.photos.sort((a, b) => a.order - b.order);
      return gallery;
    },

    actions: {
      async updateCollection(id) {
        let collection = await this.store.findRecord('collections', id); // Doesn't make sense saving something just retrieved

        collection.save();
      },

      async addPhoto(model) {
        // Should move all this to a component instead
        let order = this.get('controller').get('order');
        let src = this.get('controller').get('src');
        let heading = this.get('controller').get('heading') || '';
        let subheading = this.get('controller').get('subheading') || '';
        let collection = await this.store.findRecord('collections', model.id);
        collection.photos.push({
          order,
          src,
          heading,
          subheading
        });
        collection.save();
      },

      async editPhoto(model) {
        let collection = await this.store.findRecord('collections', model.id); // Incomplete

        collection.save(); // Do you actually want `mode.save()` instead?
      }

    }
  });

  _exports.default = _default;
});