define("raquelmullins/components/brand-header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    openHamburger: false,
    actions: {
      toggleHamburger() {
        this.toggleProperty('openHamburger');
      }

    }
  });

  _exports.default = _default;
});