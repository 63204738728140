define("raquelmullins/controllers/idea/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    actions: {
      async updateIdea() {
        let idea = await this.store.peekRecord('ideas', this.get('ideas.id'));
        idea.save();
      },

      async addSection() {
        console.log(this.get('ideas.order'));
        let section = await this.store.createRecord('sections', {
          idea: this.get('ideas.id'),
          type: 'heading1',
          order: 0,
          content: ''
        });
        section.save();
      },

      async publicChange(value) {
        let idea = await this.store.peekRecord('ideas', this.get('ideas.id'));
        idea.set('public', value);
      }

    }
  });

  _exports.default = _default;
});