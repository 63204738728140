define("raquelmullins/routes/ideas/add", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    actions: {
      addRecord: function () {
        let idea = this.store.createRecord('ideas', {
          title: this.get('controller').get('title'),
          image: this.get('controller').get('image'),
          order: this.get('controller').get('order'),
          description: this.get('controller').get('description'),
          public: false
        });
        idea.save();
      }
    }
  });

  _exports.default = _default;
});