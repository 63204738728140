define("raquelmullins/components/section-edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    sections: null,
    filteredSections: Ember.computed('sections.@each.order', function () {
      let sects = this.get('store').peekAll('sections');
      let filterSects = sects.filter(x => x.idea == this.id);
      let sortSects = filterSects.sort((a, b) => a.order - b.order);
      return sortSects;
    }),

    didReceiveAttrs() {
      this.set('sections', this.get('store').findAll('sections'));
    },

    actions: {
      sectionTypeChange(value) {
        let split = value.split(".");
        this.get('store').findRecord('sections', split[0]).then(function (sect) {
          sect.set('type', split[1]);
        });
      },

      saveSection(sectionId) {
        this.get('store').findRecord('sections', sectionId).then(function (sect) {
          sect.save();
        });
      },

      deleteSection(sectionId) {
        this.get('store').findRecord('sections', sectionId).then(function (sect) {
          sect.destroyRecord();
        });
      }

    }
  });

  _exports.default = _default;
});