define("raquelmullins/routes/collections/add", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    actions: {
      addRecord: function () {
        let collection = this.store.createRecord('collections', {
          title: this.get('controller').get('title'),
          description: this.get('controller').get('description'),
          image: this.get('controller').get('image'),
          corder: this.get('controller').get('corder'),
          video: this.get('controller').get('video'),
          photos: [{
            "order": this.get('controller').get('order'),
            "src": this.get('controller').get('src'),
            "heading": this.get('controller').get('heading') || "",
            "subheading": this.get('controller').get('subheading') || ""
          }]
        });
        collection.save();
      }
    }
  });

  _exports.default = _default;
});