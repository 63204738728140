define("raquelmullins/components/section-view", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    sections: null,
    filteredSections: Ember.computed('sections.[]', function () {
      let sects = this.get('store').peekAll('sections');
      let filterSects = sects.filter(x => x.idea == this.id);
      let sortSects = filterSects.sort((a, b) => a.order - b.order);
      return sortSects;
    }),

    didReceiveAttrs() {
      this.set('sections', this.get('store').findAll('sections'));
    }

  });

  _exports.default = _default;
});