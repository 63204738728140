define("raquelmullins/templates/bio", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "KOF94oUJ",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"container\"],[9],[0,\"\\n\\n\"],[7,\"h1\"],[9],[0,\"Bio\"],[10],[0,\"\\n\"],[7,\"p\"],[9],[0,\"Raquel Mullins (b. 1989) is a multi-disciplinary artist based in Chattanooga, TN.\\nHer drawings, installations, paintings, writing, artist books and other creative works are inspired by an uncanny intertwining of dreams, memories, and fantasy. Her work confronts questions of contemporary domestic life by archiving autobiographical experience. She illuminates wonder by highlighting small, everyday objects, spaces, and phenomena. Like the bowerbird, Raquel treats her artistic practice as a ritual act of nesting, collecting, archiving, mending, and homemaking in response to a personal history of instability. Memories of dwelling spaces serve as a rich breeding ground for her investigation of the meaning of home and belonging in the 21st century, and the role of architecture and collected objects as a locus of memory and identity. \"],[10],[0,\"\\n\\n\"],[7,\"p\"],[9],[0,\"Mullins received a BS in Art Education from the University of Tennessee at Chattanooga in 2012 and an MFA in Studio Art from the School of the Art Institute of Chicago in 2018. She taught elementary and middle school art for five years in the Chattanooga area. She now exhibits her work nationally and is an adjunct faculty member in the Painting and Drawing department at UT Chattanooga.\"],[10],[0,\"\\n\\n\"],[7,\"img\"],[11,\"src\",\"../images/raquelmullins.jpg\"],[11,\"alt\",\"Raquel Mullins\"],[11,\"class\",\"img-fluid\"],[9],[10],[0,\"\\n\"],[7,\"p\"],[11,\"class\",\"text-muted\"],[9],[0,\"\\\"home, a part\\\" exhibition. Sullivan Galleries, Chicago.\"],[10],[0,\"\\n\\n\"],[10],[0,\"\\n\\n\"],[1,[21,\"outlet\"],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "raquelmullins/templates/bio.hbs"
    }
  });

  _exports.default = _default;
});