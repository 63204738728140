define("raquelmullins/routes/idea/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    async model(params) {
      return await this.store.findRecord('ideas', params.idea_id);
    },

    setupController(controller, model) {
      this._super(...arguments);

      controller.set('ideas', model);
    }

  });

  _exports.default = _default;
});