define("raquelmullins/routes/collections/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    async model() {
      let collections = await this.store.findAll('collections').then(function (cols) {
        let newSort = cols.content.map(a => a._record);
        newSort.sort((a, b) => b.corder - a.corder);
        return newSort;
      });
      return collections;
    }

  });

  _exports.default = _default;
});